import React from 'react'
import Date from '../../Date'
import {
  StyledTag,
  StyledTitle,
  StyledDate,
  StyledCard,
  StyledCategory,
} from '../style'
import A from '@components/A'

const DefaultCard = ({
  tag,
  title,
  item,
  titleColor,
  theme = 'light',
  to = '',
  slug,
  margin,
  marginTop,
  marginBottom,
  titleMargin,
  titleSize,
  hideCategory,
  hideDate,
  homepage = false,
  slugCategory,
  slugContentType,
  className,
  ...rest
}) => {
  if (!item) return null
  let dateStart =
    item.eventStart || item.publicationDate || item.createdAt || ''
  if (item.sys) {
    dateStart = item.sys.firstPublishedAt
  }
  const dateEnd = item.eventEnd || ''
  return (
    <StyledCard
      theme={theme}
      margin={margin}
      marginTop={marginTop}
      marginBottom={marginBottom}
      className={className}
    >
      {tag && (
        <A to={slugCategory} contentType={slugContentType}>
          <StyledTag homepage={homepage}>{tag}</StyledTag>
        </A>
      )}
      <A to={to || slug} contentType={slugContentType} category={slugCategory}>
        <StyledTitle
          normalCase
          homepage={homepage}
          tag={tag}
          titleColor={theme === 'dark' ? 'white' : titleColor}
          titleMargin={titleMargin}
          titleSize={titleSize}
        >
          {title}
        </StyledTitle>
      </A>
      {!hideCategory && <StyledCategory>{item.type}</StyledCategory>}
      {!hideDate && (
        <StyledDate theme={theme} fontSize={14}>
          <Date {...rest} dateStart={dateStart} dateEnd={dateEnd} />
        </StyledDate>
      )}
    </StyledCard>
  )
}

export default DefaultCard
