import React from 'react'
import ArticleCard from './subcomponents/ArticleCard'
import { Container } from '@components/Common'
import A from '@components/A'
import { useContentType } from '@hooks'

const ArticleGrid = ({
  titleContent,
  articles,
  columnLayout,
  ctaTitle,
  ctaLink,
}) => {
  const contentType = useContentType()
  return (
    <Container
      className={`px-[15px] md:px-0 ${
        contentType === 'page' ? 'pb-[90px] pt-4' : 'pt-10'
      }`}
    >
      <div
        className="mb-5"
        dangerouslySetInnerHTML={{ __html: titleContent?.html }}
      />
      <div
        className={`grid grid-cols-1 ${
          columnLayout === 'Three Columns' ? 'md:grid-cols-3' : 'md:grid-cols-2'
        } md:gap-4 gap-10`}
      >
        {articles.map(article => (
          <ArticleCard
            key={article.id}
            id={article.id}
            description={article.description}
            image={article.image}
            columnLayout={columnLayout}
          />
        ))}
      </div>

      {ctaTitle && ctaLink && (
        <div className="mt-12 text-[22px] font-bold text-center">
          <A
            to={ctaLink}
            target="_self"
            className="text-ph-gold hover:underline hover:underline-offset-2"
          >
            {ctaTitle}
          </A>
        </div>
      )}
    </Container>
  )
}

export default ArticleGrid
